@import (reference) '~@riseart/antd-provider/dist/website/variables.less';

@legals-dialog-cls: ~'@{ra-prefix}-legals-dialog';
@legals-dialog-loading-cls: ~'@{legals-dialog-cls}-loading';

:export {
  legalsDialogCls: @legals-dialog-cls;
  legalsDialogLoadingCls: @legals-dialog-loading-cls;
}

.@{legals-dialog-cls} {
  &:not(.@{legals-dialog-loading-cls}) {
    .ant-modal-content .ant-modal-body {
      padding-top: 0;
      padding-bottom: 0;
    }

    .ant-modal-header {
      margin-bottom: 20px;

      .ant-modal-title {
        > span {
          font-weight: @ra-font-weight-light;
        }
      }
    }
  }
}
